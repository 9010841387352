import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import Grid from '../components/tiles/grid'
import Pagination from '../components/Pagination'
import MediaQuery from 'react-responsive'

export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWordpressPost
    const { title: siteTitle } = data.site.siteMetadata
    const { humanPageNumber } = pageContext

    return (
      <Layout>
        <div>
        <Helmet title={`All Posts | Page ${humanPageNumber} | ${siteTitle}`} />
        <div>
        <div>
        <MediaQuery maxWidth={639}>
          <Grid
            hasTitle={true}
            palette="grey"
            ratio={.33}
            size={22}
          >
            <h1>All Posts</h1>
          </Grid>
        </MediaQuery>

        <MediaQuery minWidth={640} maxWidth={959}>
          <Grid
            hasTitle={true}
            palette="grey"
            ratio={.33}
            size={176}
          >
            <h1>All Posts</h1>
          </Grid>
        </MediaQuery>

        <MediaQuery minWidth={960} maxWidth={1439}>
          <Grid
            hasTitle={true}
            palette="grey"
            ratio={.33}
            size={217}
          >
            <h1>All Posts</h1>
          </Grid>
        </MediaQuery>
        <MediaQuery minWidth={1440}>
          <Grid
            hasTitle={true}
            palette="grey"
            ratio={.33}
            size={484}
          >
            <h1>All Posts</h1>
          </Grid>
        </MediaQuery>
        </div>
        <PostList posts={posts} />
        <Pagination pageContext={pageContext} pathPrefix="/" />
        </div>
        </div>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
